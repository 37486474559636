import './elonyok.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import pic1 from './MoraNova_bekapcsolva_logoval_meddiamond_trianglebg.jpg';
import pic2 from './Névtelen terv (44).png';
import pic3 from './Névtelen terv (45).png';


function Elonyok(){
    
    return (
        <div className="container-elonyok">
          <style>
          @import url('https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght@9..144,300&family=Inter&display=swap');
          </style>
          <div className='elonyok-titles'>
            <p className='elonyok-main-title'>ELŐNYÖK</p>
            <p className='elonyok-second-title'>A MORA módszer előnyei</p>
          </div>

          <div className='elonyok-content'>
            <div>
                <img alt='older woman on the beach' src={pic3}></img>
                <p>Fájdalommentes</p>
                <p>A kezelések teljesen fájdalommentesek. Továbbá a MORA módszer lehetőséget nyújt alternatív fájdalom csillapításra is, mely alkalmazható 
                nagyon akut, akut, szubakut vagy krónikus fájdalmak esetén is.
                </p>
            </div>
            <div>
                <img alt='mora-készülék' src={pic1}></img>
                <p>Folyamatos fejlesztések</p>
                <p>A MORA®-módszert immár több mint 40 éve fejlesztik Németországban. A folyamatos fejlesztéseknek köszönhetően 
                    naprakész módszerekkel rendelkezünk, a modern világ problémáihoz igazodva.
                </p>
            </div>
            <div>
                <img alt='kid with a yellow sunglasses' src={pic2}></img>
                <p>Gyermekeknél is alkalmazható</p>
                <p>Már kisgyermek kortól alkalmazható mind az állapotfelmérés mind a kezelés.
                    Rendelőnkben a gyermekekre is gondolunk, lehetőség van mesét vagy rajzfilmet nézni a kezelések közben, így a gyógyulással töltött 
                    idő biztosan kellemesen fog telni. 
                    Azok a gyerekek is igénybe vehetik a mesenézést akik várnak családtagjaikra, csak kísérőként érkeznek.
                </p>
            </div>
          </div>
        </div>
      );
}

export default Elonyok;
import './rendszer.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import FadeLeft from '../../Animation/FadeIn';
import FadeIn from '../../Animation/FadeIn';


function Rendszer(){
    
    const [open, set] = useState(true)

    return (
        <div className="container-rendszer">
          <style>
          @import url('https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght@9..144,300&family=Inter&display=swap');
          </style>
          <div className='rendszer-titles'>
            <p className='rendszer-main-title'>RENDSZER</p>
            <p className='rendszer-second-title'>A rendszer működése</p>
          </div>

          <div className='rendszer-content'>
            <div className='rendszer-part'>
                <FadeLeft open={open}>
                    <p className='rendszer-text'>A MORA®-módszert immár több mint 40 éve fejlesztik Németországban.
                            A kutatások során évezredek óta bevált, tradicionális módszereket, 
                            saját eredményeket és tanulmányokat valamint modern technológiai 
                            eszközöket ötvözve hozták létre a MORA®-módszer mai, innovatív orvostechnikai eszközeit, 
                            amelyeknek köszönhetően új mércét hoztak létre az alternatív medicína terén.</p>
                </FadeLeft>
            </div>
            <div className='rendszer-part'>
                <FadeIn open={open}>
                <p className='rendszer-text'>A MORA® módszer az elektromágneses rezonancián, elektrohomeopátián, elektroakupunktúrán, 
                        és biofizikai kutatásokon alapuló, állapotfelmérő és kezelő módszer.</p>
                </FadeIn>
            </div>
            <div className='rendszer-part'>
                <FadeLeft open={open}>
                <p className='rendszer-text'>A kezelés célja a különböző elektromágneses rezgéseken alapuló struktúrák 
                        (szervek, szövetek, szervek) energetikai egyensúlyának erősítése/helyreállítása.</p>
                </FadeLeft>
            </div>
          </div>
        </div>
      );
}

export default Rendszer;
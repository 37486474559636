import Header from "./Components/Header/header"
import './App.css';
import React, { useEffect, useState } from 'react';
import { FaArrowCircleUp } from 'react-icons/fa';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Szolgaltatasok from "./Components/Szolgaltatasok/szolgaltatasok";
import Bemutatas from '../src/Components/Bemutatas/bemutatas';
import Velemenyek from './Components/Velemenyek/velemenyek';
import Kerdesek from './Components/Kerdesek/kerdesek';
import Footer from './Components/Footer/footer';
import Kapcsolat from "./Components/Kapcsolat/kapcsolat";
import Navbar from "./Components/Navbar/navbar";
import HeaderMora from "./Components/Header-MORA/header-mora";
import Elonyok from './Components/Elonyok/elonyok';
import Teruletek from "./Components/Teruletek/teruletek";
import Rendszer from './Components/Rendszer/rendszer';
import Info from './Components/Info/info';
import GeneralFooter from './Components/GeneralFooter/generalfooter';
// import CookieConsent from "react-cookie-consent";
import ReactGA from 'react-ga';


function initializeReactGA() {
    ReactGA.initialize('7590475244'); // Replace with your tracking ID
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

function App() {
    initializeReactGA();
    const [showScrollToTop, setShowScrollToTop] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 300) {
                setShowScrollToTop(true);
            } else {
                setShowScrollToTop(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

  return (
      <>
        {/* <CookieConsent
            location="bottom"
            buttonText="Sure man!!"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#2B373B" }}
            containerClasses="alert alert-warning col-lg-12"
            contentClasses="text-capitalize"
            buttonStyle={{ 
                background: "#1ad9eb", 
                fontSize: "14px", 
                border: "none",
                radius: "6px"}}
            expires={150}
            >
            This website uses cookies to enhance the user experience.{" "}
            <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span>
        </CookieConsent> */}
          <Router>
                  <Routes>
                      <Route path="/" element={[
                        <Navbar key="navbar"/>,
                            <Header key="header" />,
                            <Szolgaltatasok key="szolgaltatasok" />,
                            <Bemutatas key="bemutatas" />,
                            <Velemenyek key="velemenyek" />,
                            <Kerdesek key="kerdesek" />,
                            <Footer key="footer" />,
                            ]}
                        />
                      <Route path="/kapcsolat" element={[ <Navbar key="navbar"/>, <Kapcsolat key="kapcsolat"/>, <GeneralFooter key="generalfooter"/>]} />
                      <Route path="/mora-modszer" element={[ 
                      <Navbar key="navbar"/>, 
                      <HeaderMora key="headermora"/>, 
                      <Elonyok key="elonyok"/>, 
                      <Teruletek key="teruletek"/>,
                      <Rendszer key="rendszer"/>,
                      <Info key="info"/>,
                      <GeneralFooter key="generalfooter"/>]} />
                  </Routes>
          </Router>
          {showScrollToTop && (
                <div className="scroll-to-top" onClick={scrollToTop}>
                    <FaArrowCircleUp size={36} />
                </div>
            )}
      </>
  );
}

export default App;


import './header.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import bigpic from './4-best másolata.png';
import smallpic from './mora2.jpg'
import {NavLink} from "react-router-dom";
import ButtonAnimation from '../../Animation/ButtonAnimation';

function Header(){
    
    return (
        <div className="container">
          <style>
          @import url('https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght@9..144,300&family=Inter&display=swap');
          </style>
          


          <header className="header">
            <div className="header-content">
              <div className='header-main-text'>
                <h1 className='header-title'>Segítünk egy egészségesebb jövő kialakításában</h1>
                <p className='header-text'>
                A testünkben zajló folyamatokat elektromágneses rezgések irányítják, 
                tehát ha az elektromágneses rezgéseket korrigáljuk, a folyamatok kedvező irányba változhatnak.
                </p>
              </div>
              
              <div className='header-buttons'>
              <ButtonAnimation>
                <NavLink
                    activeclassname="active" 
                    to="/kapcsolat"
                    smooth="true"
                    offset={-70}
                    duration={200}
                    className="contact-button"
                  >
                    <button className="contact-button-header">Kapcsolat</button>
                  </NavLink>
                </ButtonAnimation>
                <ButtonAnimation>
                  <NavLink
                    activeclassname="active" 
                    to="/mora-modszer"
                    smooth="true"
                    offset={-70}
                    duration={200}
                    className="link-to-mora"
                  >
                    <p className="nav-title nav-link2">A MORA® módszerről bővebben →</p>
                  </NavLink>
                </ButtonAnimation>
              </div>
              
            </div>
            <div className="header-images">
              <div className='big-image'>
                <img
                  className="big-image"
                  src={bigpic}
                  alt="Big"
                />
              </div>
              <div className='secondary-image'>
                <img
                  className="small-image"
                  src={smallpic}
                  alt="Small"
                />
              </div>
            </div>
          </header>
        </div>
      );
}

export default Header;
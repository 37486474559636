import './header-mora.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import pic from './enlarge_15.jpg';
import {NavLink} from "react-router-dom";
import ButtonAnim from '../../Animation/ButtonAnimation'


function HeaderMora(){
    
    return (
        <div className="container">
          <style>
          @import url('https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght@9..144,300&family=Inter&display=swap');
          </style>

          <header className="header-mora">
            <div className="header-mora-content">
              <h1 className='header-mora-title'>MORA® módszer</h1>
              <p className='header-mora-text'>
              Célja a sejtek közötti zavartalan kommunikáció helyreállítása, mindazon tényezők szűrése, 
              amelyek a szervezetet terhelik, akár még az előtt, hogy funkcionális vagy szervszintű elváltozást okoznának.
              </p>
              <div className='header-button'>
                <ButtonAnim>
                <NavLink
                    activeclassname="active" 
                    to="/kapcsolat"
                    smooth="true"
                    offset={-70}
                    duration={200}
                    className="link-to-kapcsolat"
                  >
                    <p className="nav-title nav-link2">Időpont foglalás →</p>
                  </NavLink>
                </ButtonAnim>
                  
              </div>
            </div>

            <div className="header-mora-image">
              <div className='mora-modszer-image'>
                <img
                  className="big-image-mora"
                  src={pic}
                  alt="Big"
                />
              </div>
            </div>
          </header>
        </div>
      );
}

export default HeaderMora;
import './velemenyek.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import pic1 from './cttbDiok2tinAgP1D4paKAVydjQ.webp';
import pic2 from './JhbGle453RWF0QjzZnSxbtxRBiA.webp';
import pic3 from './JmL4zsSgYmR6MF7Fz8e7n1OwA.webp';
import pic4 from './pOCRgMY1EtaSLV0lmXV2e9YiU.webp';
import pic5 from './s2SIXaoRctF7FYJRq7cEVtGQI.webp';
import pic6 from './UImn1wDxDUWfAgrDb7qP3LGp8A.webp';


function Velemenyek(){
    
    return (
        <div className="container-vel">
          <style>
          @import url('https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght@9..144,300&family=Inter&display=swap');
          </style>
          <div className='titles-vel'>
            <p className='title-main-vel'>VÉLEMÉNYEK</p>
            <p className='title-second'>Páciensek visszajelzései</p>
          </div>
          <div className='box-cont-vel'>
            <div className='box-cont-vel1'>
                <div className='box-vel'>
                    <div className="content-vel">

                    <div className='inner-content'>
                    <img className='human-pic-vel' src={pic6} alt="humans" />
                    <h2 className='negyzet-title-vel'>Zsuzsanna</h2>
                    </div>
                    <p className='negyzet-text-vel'>"A kezelés minden egyes alkalommal teljes relaxálást és feltöltődést nyújtott kellemes környezetben,
                    nem mellesleg az asszisztens jelenléte és segítőkészsége is maga a gyógyulás 🙂. Szívből ajánlom mindenkinek!"</p>

                    </div>
                </div>
                <div className='box-vel'>
                   <div className="content-vel">

                      <div className='inner-content'>
                      <img className='human-pic-vel' src={pic5} alt="humans" />
                      <h2 className='negyzet-title-vel'>Rita</h2>
                      </div>
                      <p className='negyzet-text-vel'>"4 napig tartó, lázcsillapító ellenére is folyamatosan 38,5-39 fokos lázzal küszködtem, mikor a Doktornő javaslatára, lázasan felvettem egy vírusok elleni Mora-kezelést. 
                          Már közvetlen a kezelés után éreztem, hogy sokkal energikusabb, jobb kedvűbb lettem. 
                          A kezelés utáni éjszaka erős fejfájással küzdöttem, de már csak hőemelkedésem volt, nem kellett bevennem lázcsillapítót, éreztem, hogy dolgozik a szervezetem. 
                          Másnap délelőttre viszont már a hőemelkedésem és a fejfájás is megszűnt, azóta nem lázasodtam be🙏😇 
                          Köszönöm a lehetőséget és a segítséget !💕🫶🏻🥰"</p>

                   </div>
                </div>
            </div>
            <div className='box-cont-vel2'>
                
                <div className='box-vel'>
                    <div className="content-vel">

                        <div className='inner-content'>
                        <img className='human-pic-vel' src={pic3} alt="humans" />
                        <h2 className='negyzet-title-vel'>Gabriella</h2>
                        </div>
                        <p className='negyzet-text-vel'>"Odafigyelve gondoskodás. Mindenkinek, amire pont neki szüksége van. Sokszínű és holisztikus."</p>
                         
                    </div>
                </div>
                
                <div className='box-vel'>
                   <div className="content-vel">

                       <div className='inner-content'>
                       <img className='human-pic-vel' src={pic1} alt="humans" />
                       <h2 className='negyzet-title-vel'>János</h2>
                       </div>
                       <p className='negyzet-text-vel'>"Jobban érezhető volt a hatása mint a fájdalomcsillapítónak."</p>
 
                   </div>
                </div>
                
            </div>
          </div>
        </div>
      );
}

export default Velemenyek;
import './kapcsolat.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { React, useState, useEffect } from 'react';
import { FaHome, FaPhone, FaEnvelope, FaFacebook } from 'react-icons/fa';
import ButtonAnim from '../../Animation/ButtonAnimation';
import emailjs from '@emailjs/browser';

function Kapcsolat() {
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            process.env.REACT_APP_SERVICE_ID,
            process.env.REACT_APP_TEMPLATE_ID,
            e.target,
            process.env.REACT_APP_PUBLIC_KEY)
        .then((result) => {
            setSuccessMessage('Köszönjük az üzenetét! Hamarosan kapcsolatban lépünk önnel!');
        }, (error) => {
            setErrorMessage('Hiba az email küldés során! Kérjük próbálja meg újra.');
        });
        setSuccessMessage('Köszönjük az üzenetét! Hamarosan kapcsolatban lépünk önnel!');

        e.target.reset()
    };

    const handleEmptyInput = (event) => {
        const textfield = event.target;

        textfield.setCustomValidity('');
    
        if (!textfield.validity.valid || textfield.value.trim() === '') {
            if (textfield.type === "email") {
                textfield.setCustomValidity('A megadott email cím helytelen!');
            }
            else {
                textfield.setCustomValidity('Kérem töltse ki ezt a mezőt!');
            }
        }
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setSuccessMessage('');
        }, 10000);
    
        return () => clearTimeout(timeoutId);
    }, [successMessage]);

    const handleClick = () => {
        window.open("https://www.facebook.com/p/MORA-Ter%C3%A1pia-Cegl%C3%A9d-100063868371044/?paipv=0&eav=Afaej327utxwcYZnWoATRiCqHvYam9037zDTeDgVDQuGBiOMhN_jqme9XURROZ7XugE&_rdr", '_blank');
        
      };
    
    return (
        <div className="container-kapcsolat">
          <style>
          @import url('https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght@9..144,300&family=Inter&display=swap');
          </style>
                <div className="content-kapcsolat">
                    <div className="elerhetosegek">
                        <p className='title-main-vel'>Elérhetőségek</p>
                        
                        <div>
                            <p className='kapcsolat-text'>Tegye meg az első lépést egészségért. 
                                Időpontfoglalásért és bármilyen felmerülő kérdéssel kapcsolatban kérem keressen minket bizalommal az alábbi elérhetőségeken.</p>
                        </div>
                        <div className='container-nevjegy'>
                            <div className="container-elerhetoseg">
                                <FaHome className='' size={22} color='#1AD9EB'/>
                                <p className='kapcsolat-text'>2700 Cegléd Kossuth ferenc u. 21. 4/16.</p>
                            </div>
                            <div className="container-elerhetoseg">
                                <FaPhone className='' size={22} color='#1AD9EB'/>
                                <p className='kapcsolat-text'>+36 70 677 11 90</p>
                            </div>
                            <div className="container-elerhetoseg">
                                <FaEnvelope className='' size={22} color='#1AD9EB'/>
                                <p className='kapcsolat-text'>muhi.katalin@gmail.com</p>
                            </div>
                            <div className="facebook" onClick={handleClick}>
                                <FaFacebook className='facebook-icon' size={22} color='#1AD9EB'/>
                            </div>
                            <div className="google-map">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2712.1016560327107!2d19.797311074825444!3d47.17544621765353!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x474170f83356764f%3A0x366cffa9f421b56a!2sCegl%C3%A9d%2C%20Kossuth%20Ferenc%20u.%2023%2C%202700!5e0!3m2!1shu!2shu!4v1706440355378!5m2!1shu!2shu"
                                    width="400" height="300" style={{
                                    border: "0",
                                    width: "400px"
                                }} allowFullScreen="" loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade" title="Google Maps Embed"></iframe>
                            </div>
                        </div>
                    </div>

                    
                    <div className="email-kapcsolat-form">
                        <div className="cim">
                            <p className='title-main-vel'>Írjon nekünk!</p>
                        </div>
                        <form onSubmit={sendEmail}>
                            <div className='nevek'>
                                <div className="form-group">
                                <label className='form-text'>Vezetéknév</label>
                                <input type="text" name="first_name" required onInvalid={handleEmptyInput} />
                                </div>
                                <div className="form-group">
                                    <label className='form-text'>Keresztnév</label>
                                    <input type="text" name="last_name" required onInvalid={handleEmptyInput} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className='form-text'>Email</label>
                                <input type="email" name="email_from" required onInvalid={handleEmptyInput} />
                            </div>
                            <div className="form-group">
                                <label className='form-text'>Telefonszám</label>
                                <input type="tel" name="phone_number" required onInvalid={handleEmptyInput} />
                            </div>
                            <div className="form-group">
                                <label className='form-text'>Üzenet</label>
                                <textarea name="message" rows="4" required onInvalid={handleEmptyInput} />
                            </div>
                            <div className='form-button-region'>
                                    <div>
                                        <ButtonAnim>
                                            <button className="kuldes-button" type="submit">Küldés</button>
                                        </ButtonAnim>
                                    </div>
                                    <div>
                                        {successMessage && 
                                        <div className="success-message">{successMessage}</div>}
                                        {errorMessage && 
                                        <div className="error-message">{errorMessage}</div>}
                                    </div>
                            </div>
                        </form>
                    </div>
                    
                </div>
            
            
        </div>
      );
}

export default Kapcsolat;
import './bemutatas.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import pic1 from './enlarge_idopont_elojegyzes_checklist_terapeuta-980x1325.jpg';
import pic2 from './morahoz.PNG';
import ButtonAnim from '../../Animation/ButtonAnimation';
import {NavLink} from "react-router-dom";
import FadeIn from '../../Animation/FadeIn';


function Bemutatas(){
    
    return (
        <div className="container-bem">
          <style>
          @import url('https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght@9..144,300&family=Inter&display=swap');
          </style>
          <div className='box-bem-1'>
            <img className='pic1' src={pic1} alt="doctor" />
            <FadeIn>
            <div className='box-text-bem'>
                  <p className='bem-title1'>ÁLLAPOTFELMÉRÉS</p>
                  <p className='bem-title2'>Egyedülálló orvostechnikai eszköz</p>
                  <p className='bem-text'>Szolgáltatásaink az egészség megőrzését, 
                  a panaszok megszűntetését és azok kiváltó okának felderítését, megszűntetését célozzák.</p>
                  <ButtonAnim>
                  <NavLink
                                        activeclassname="active" 
                                        to="/kapcsolat"
                                        smooth="true"
                                        offset={-70}
                                        duration={200}
                                        className="nav-link"
                                      >
                                        <button className='bem-button'>Időpontfoglalás</button>
                                      </NavLink>
                  </ButtonAnim>
              </div>
            </FadeIn>
              
            
          </div>
          <div className='box-bem-2'>
            <FadeIn>
            <div className='box-text-bem'>
                    <p className='bem-title1'>KEZELÉS</p>
                    <p className='bem-title2'>Teljes relaxálás és feltöltődés</p>
                    <p className='bem-text'>A MORA® Nova készülék érzékeli és elemzi a kezelt személy 
                    szervezetének elektromágneses frekvencia mintáit. 
                    A diszharmónikus mintákat feldolgozást követően visszavezeti a szervezetbe a 
                    kimeneti elektródákon át.</p>
                    <ButtonAnim>
                    <NavLink
                                          activeclassname="active" 
                                          to="/kapcsolat"
                                          smooth="true"
                                          offset={-70}
                                          duration={200}
                                          className="nav-link"
                                        >
                                          <button className='bem-button'>Időpontfoglalás</button>
                                        </NavLink>
                    </ButtonAnim>
                </div>
            </FadeIn>
              
            
              <img className='pic2' src={pic2} alt="moraterapia" />
          </div>
        </div>
      );
}

export default Bemutatas;
import './footer.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import logo from '../Header/1660149889759865_page-0001-removebg-preview.png'
import { FaFacebook } from 'react-icons/fa';
import {NavLink} from "react-router-dom";
import ButtonAnim from '../../Animation/ButtonAnimation';


function Footer(){

  const handleClick = () => {
    window.open("https://www.facebook.com/p/MORA-Ter%C3%A1pia-Cegl%C3%A9d-100063868371044/?paipv=0&eav=Afaej327utxwcYZnWoATRiCqHvYam9037zDTeDgVDQuGBiOMhN_jqme9XURROZ7XugE&_rdr", '_blank');
    
  };

    return (
        <div className="container-foot">
          <style>
          @import url('https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght@9..144,300&family=Inter&display=swap');
          </style>
          <h1 className='title-footer'>Tegyen egészségéért és jelentkezzen be hozzánk!</h1>
          <p className='text-footer'>További időpont egyeztetésért és információkért kérjük 
            keressen minket a megadott telefonszámon, vagy írjon nekünk a Kapcsolat fül alatt.</p>
            <ButtonAnim>
                <NavLink
                    activeclassname="active" 
                    to="/kapcsolat"
                    smooth="true"
                    offset={-70}
                    duration={200}
                    className="button-footer"
                  >
                    <button className='button-footer'>Kapcsolat →</button>
                  </NavLink>
                </ButtonAnim>

          <div className='footer-part2'>
            <div>
                <img className='footer-logo' src={logo} alt="Logo" />
            </div>
            <div className='oldalak'>
                <p className='bold-text-footer'>Oldalak</p>
                <p>Főoldal</p>
                <p>MORA® Módszer</p>
                <p>Kapcsolat</p>
                <FaFacebook className='facebook-icon' size={22} color='#1AD9EB' onClick={handleClick}/>
            </div>
            <div className='nyitvatartas'>
                <p className='bold-text-footer'>Nyitvatartás</p>
                <p>Előzetes egyeztetés alapján.</p>
                <a href="/Adatkezelési tájékoztató.pdf" className='privacy-link'>Adatvédelmi tájékoztató</a>
            </div>
          </div>
          <p className='footer-rights'>© 2024 MORA®, Inc. All rights reserved.</p>
        </div>
      );
}

export default Footer;
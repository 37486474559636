import './navbar.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import logo from '../Header/1660149889759865_page-0001-removebg-preview.png';
import {NavLink} from "react-router-dom";


function Navbar(){

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };
    
    return (
        <div className="container">
          <style>
          @import url('https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght@9..144,300&family=Inter&display=swap');
          </style>
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
              <div className="navbar-div">
                <a className="navbar-brand" href="/">
                  <img src={logo} alt="Logo" />
                </a>

                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleMobileMenu}
                >
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div
                  className={`collapse navbar-collapse ${isMobileMenuOpen ? 'show' : ''}`}
                >
                  <ul className="navbar-nav mobile-menu">
                  <li className="nav-items">
                      <NavLink
                        activeclassname="active" 
                        to="/"
                        smooth="true"
                        offset={-70}
                        duration={200}
                        className="nav-link"
                        onClick={closeMobileMenu}
                      >
                        <p className="nav-title">Főoldal</p>
                      </NavLink>
                    </li>
                    <li className="nav-items">
                      <NavLink
                        activeclassname="active" 
                        to="/mora-modszer"
                        smooth="true"
                        offset={-70}
                        duration={200}
                        className="nav-link"
                        onClick={closeMobileMenu}
                      >
                        <p className="nav-title">MORA® módszer</p>
                      </NavLink>
                    </li>
                    <li className="nav-items">
                      <NavLink
                        activeclassname="active" 
                        to="/kapcsolat"
                        smooth="true"
                        offset={-70}
                        duration={200}
                        className="nav-link"
                        onClick={closeMobileMenu}
                      >
                        <p className="nav-title">Kapcsolat</p>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      );
}

export default Navbar;
import './teruletek.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import picaller from './antihistamines.png';
import picalum from './aluminium.png';
import picfog from './tooth.png';
import picgyull from './inflammation.png';
import picfert from './coronavirus.png';
import picfajd from './headache (1).png';
import pickedely from './smile (1).png';
import picfejfaj from './headache.png';
import picauto from './autoimmune-disease.png';
import pichorm from './uterus.png';
import picdag from './cancer-cell.png';
import picbor from './psoriasis.png';
import picno from './slim-body.png';
import picgomba from './uterus (1).png';
import picmutet from './surgery.png';
import picmozgas from './pain-in-joints.png';
import picgeo from './geophysics.png';
import picelektro from './electromagnetic.png';


function Teruletek(){

    const cardsData = [
        { id: 1, image: picaller, caption: 'Allergia' },
        { id: 2, image: picalum, caption: 'Nehézfém terheltség' },
        { id: 3, image: picfog, caption: 'Fogászati alkalmazások' },
        { id: 4, image: picgyull, caption: 'Gyulladások' },
        { id: 5, image: picfert, caption: 'Fertőzések' },
        { id: 6, image: picfajd, caption: 'Fájdalom' },
        { id: 7, image: pickedely, caption: 'Kedélyjavítás' },
        { id: 8, image: picfejfaj, caption: 'Fejfájás' },
        { id: 9, image: picauto, caption: 'Autoimmun betegségek' },
        { id: 10, image: pichorm, caption: 'Hormonzavarok' },
        { id: 11, image: picdag, caption: 'Daganatok kiegészítő kezelései' },
        { id: 12, image: picbor, caption: 'Bőrbetegségek' },
        { id: 13, image: picno, caption: 'Nőgyógyászati problémák' },
        { id: 14, image: picgomba, caption: 'Gombás betegségek - Candida' },
        { id: 15, image: picmutet, caption: 'Műtét utáni kezelések' },
        { id: 16, image: picmozgas, caption: 'Mozgásszervi betegségek' },
        { id: 17, image: picgeo, caption: 'Geopátiás terheltség' },
        { id: 18, image: picelektro, caption: 'Elektrostressz' }
      ];
    
    return (
        <div className="container-teruletek">
          <div className='teruletek-titles'>
            <p className='teruletek-main-title'>TERÜLETEK</p>
            <p className='teruletek-second-title'>Milyen esetekben segíthet a MORA® módszer</p>
          </div>

            <div className='content-teruletek'>
                {cardsData.map(card => (
                <div key={card.id} className='small-card'>
                    <img src={card.image} alt={`Card ${card.id}`} />
                    <p>{card.caption}</p>
                </div>
                ))}
            </div>
          
        </div>
      );
}

export default Teruletek;
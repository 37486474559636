import './info.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import pic1 from './pexels-pixabay-416528.jpg';
import pic2 from "./pexels-cottonbro-studio-3825295.jpg";
import pic3 from './roman-kraft-WUvBROPOsuo-unsplash.jpg';


function Info(){
    
    return (
        <div className="container-info">
          <style>
          @import url('https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght@9..144,300&family=Inter&display=swap');
          </style>
          <div className='info-titles'>
            <p className='info-main-title'>INFO</p>
            <p className='info-second-title'>Amit a kezdés előtt tudni érdemes</p>
          </div>

          <div className='info-cards'>
            <div className='card'>
                <img className='info-card-pic' src={pic1} alt="doctor" />
                <div className='box-text-info'>
                    <p className='info-title1'>HIDRATÁLÁS</p>
                    <p className='info-title2'>Megfelelő hidratáltság</p>
                    <p className='info-text'>Napi 40ml víz / testtömeg kilogram, (összesen legalább 2 liter)
                     víz mindennapos fogyasztása minimum ajánlott a szervezet megfelelő működésének megteremtéséhez.</p>
                </div>
            </div>
            <div className='card card2'>
                <div className='box-text-info'>
                    <p className='info-title1'>INFO</p>
                    <p className='info-title2'>Információ egyéb, más kezelésről</p>
                    <p className='info-text'>Bármilyen egyéb, párhuzamos kezelés, terápia befolyásolhatja az állapotfelmérés eredményét, 
                    és a kezelés kimenetelét, ezért arról kérjük tájékoztassa MORA® terapeutáját!</p>
                </div>
                <img className='info-card-pic' src={pic3} alt="doctor" />
            </div>
            <div className='card'>
                <img className='info-card-pic' src={pic2} alt="doctor" />
                <div className='box-text-info'>
                    <p className='info-title1'>KONTRAINDIKÁCIÓ</p>
                    <p className='info-title2'>Kizáró okok, tényezők</p>
                    <p className='info-text'>Az alábbi problémák fennállása eretén sem állapotfelmérés, sem kezelés nem végezhető a MORA®-módszerrel!<br></br>
                    <br></br>
                    - terhesség<br></br>

                    - elektromos implantátumok<br></br>

                    - epilepszia<br></br>

                    - szoptatás<br></br>

                    - súlyos depresszió<br></br>

                    - pszichózis<br></br>

                    - szervtranszplantáció<br></br>

                    - akut életveszély (például akut tüdőembólia,         szívinfarktus, stroke, akut mérgezés)</p>
                </div>
            </div>
          </div>

          
        </div>
      );
}

export default Info;
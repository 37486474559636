import './szolgaltatasok.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import human1 from './Humaaans - Standing.png'
import human2 from './Humaaans - Wireframe (1).png'
import human3 from './Humaaans - 3 Characters (1).png'
import human4 from './Humaaans - Sitting.png'
import {NavLink} from "react-router-dom";

function Szolgaltatasok(){
    
    return (
        <div className="container-szolg">
          <style>
          @import url('https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght@9..144,300&family=Inter&display=swap');
          </style>
          <div className='titles'>
            <p className='title-main'>SZOLGÁLTATÁSAINK</p>
            <p className='title-second'>Teljeskörű szolgáltatások</p>
          </div>

          <div className="rectangles">
            <div className='first-group'>
                <div className="rectangle">
                  <div className="content">
                    <h2 className='negyzet-title'>MORA® Módszer</h2>
                    <p className='negyzet-text'>Teljeskörű MORA® állapotfelméréssel feltérképezhetőek a testben zajló folyamatok.</p>
                    <div className='rec-cont1'>
                        <div className='rec-cont2-1'>
                            <p className='negyzet-text negyzet-text1'>Ez az orvostechnikai eszköz sokrétű alkalmazási opciót kínál, 
                            amelyekkel a felhasználója számos problémára megoldást találhat.</p>
                              <NavLink
                                      activeclassname="active" 
                                      to="/kapcsolat"
                                      smooth="true"
                                      offset={-70}
                                      duration={200}
                                      className="nav-link"
                                    >
                                      <p className="negyzet-link">Foglalok időpontot →</p>
                                    </NavLink>
                        </div>
                        <img className='humans' src={human1} alt="humans" />
                    </div>
                  </div>
                </div>
                <div className="rectangle">
                <div className="content">
                    <h2 className='negyzet-title'>Allergének tesztelése</h2>
                    <p className='negyzet-text '>A MORA® készülék lehetőséget kínál mint egy több száz allergén tesztelésére is, 
                    mindezt fájdalom mentesen és melléhatások nélkül.</p>
                    <div className='rec-cont1'>
                      <div className='rec-cont2'>
                          <p className='negyzet-text negyzet-text2'>Tudunk vizsgálni ételallergiákat (glutén, laktóz, tojás, olajok, magvak, gabonafélék, zöldségek, húsok és még sok más), 
                          kozmetikai anyagokat, polleneket, fogászati allergének stb. </p>
                            <NavLink
                                    activeclassname="active" 
                                    to="/kapcsolat"
                                    smooth="true"
                                    offset={-70}
                                    duration={200}
                                    className="nav-link"
                                  >
                                    <p className="negyzet-link">Foglalok időpontot →</p>
                                  </NavLink>
                      </div>
                      <img className='humans' src={human2} alt="humans" />
                    </div>
                  </div>
                </div>
            </div>
            <div className='second-group'>
                <div className="rectangle">
                <div className="content">
                    <h2 className='negyzet-title'>Problémaspecifikus kezelések</h2>
                    <p className='negyzet-text'>A MORA® terápia sikeresen ötvözi a homeopátiát, 
                          a több ezer éves kínai gyógymódot, a XXI.század legmodernebb biofizikai gyógyító módszereivel.</p>
                    <div className='rec-cont1'>
                      <div className='rec-cont2-3'>
                          <p className='negyzet-text'>Az állapotfelmérés után minden páciens egyéni kezelést kap.</p>
                            <NavLink
                                    activeclassname="active" 
                                    to="/kapcsolat"
                                    smooth="true"
                                    offset={-70}
                                    duration={200}
                                    className="nav-link"
                                  >
                                    <p className="negyzet-link">Foglalok időpontot →</p>
                                  </NavLink>
                      </div>
                      <img className='humans3' src={human3} alt="humans" />
                    </div>
                  </div>
                </div>
                <div className="rectangle">
                <div className="content">
                    <h2 className='negyzet-title'>Psychophonia-Terápia</h2>
                    <p className='negyzet-text'>Speciális terápiás hanghatások fejhallgatón keresztül.</p>
                    <div className='rec-cont1'>
                      <div className='rec-cont2-4'>
                          <p className='negyzet-text'>A Psychophonia a MORA terápia komplex kiegészítője, az adott betegségre ható hallható frekvenciák. 
                          Már a kezelés elején alfa-közeli agykérgi állapotba kerül a kezelt személy, sokszor el is alszik.</p>
                            <NavLink
                                    activeclassname="active" 
                                    to="/kapcsolat"
                                    smooth="true"
                                    offset={-70}
                                    duration={200}
                                    className="nav-link"
                                  >
                                    <p className="negyzet-link">Foglalok időpontot →</p>
                                  </NavLink>
                      </div>
                      <img className='humans4' src={human4} alt="humans" />
                    </div>
                  </div>
                </div>
            </div>
            
          </div>
        </div>
      );
}

export default Szolgaltatasok;
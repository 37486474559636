import './kerdesek.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';



function Kerdesek(){

    const [openQuestion, setOpenQuestion] = useState(null);

    const questions = [
        {
            id: 1,
            question: 'Igényel-e felkészülést a MORA®-módszer igénybevétele?',
            answer: 'Megfelelő hidratáltságot. Érdemes a víz fogyászta odafigyelni az állapotfelmérés előtt.',
        },
        {
            id: 2,
            question: 'Mennyi időt vesz igénybe az állapotfelmérés?',
            answer: 'Az állapotfelmérés általában 2-2,5 órát vesz igénybe.',
        },
        {
            id: 3,
            question: 'Hány éves kortól ajánlott a MORA®-módszer?',
            answer: 'Már kisgyermekkortól lehet hozzánk jönni, legfiatalabb páciensünk 5 éves volt.',
        },
        {
            id: 4,
            question: 'Van-e mellékhatása a MORA®-módszernek?',
            answer: 'Nincs.',
        },
        {
            id: 5,
            question: 'Milyen esetben NEM javallott a MORA®-módszer?',
            answer: 'Az alábbi problémák fennállása eretén sem állapotfelmérés, sem kezelés nem végezhető a MORA®-módszerrel: terhesség, elektromos implantátumok, epilepszia, szoptatás, súlyos depresszió, pszichózis, szervtranszplantáció, akut életveszély (például akut tüdőembólia, szívinfarktus, stroke, akut mérgezés)'
        }
    ];

    const handleQuestionClick = (id) => {
        setOpenQuestion(openQuestion === id ? null : id);
    };
    
    return (
        <div className="container-ker">
          <style>
          @import url('https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght@9..144,300&family=Inter&display=swap');
          </style>
          <div className='titles-vel'>
            <p className='title-main-vel'>KÉRDÉSEK</p>
            <p className='title-second'>Gyakran ismételt kérdések</p>
          </div>
          <div className="questions-container">
          {questions.map((q) => (
                <div key={q.id} className="question">

                    <div className="question-title" onClick={() => handleQuestionClick(q.id)}>
                        <div>
                            {q.question}
                        </div>
                        <div>
                            {openQuestion === q.id ? <FaMinus style={{ color: '#FFA245', cursor: 'pointer' }}/> : <FaPlus style={{ color: '#FFA245', cursor: 'pointer' }} />}
                        </div>
                    </div>
<br></br>
                    <div className="question-answer">
                    {openQuestion === q.id && (
                        <div className="answer">{q.answer}</div>
                    )}
                    </div>
                    
                </div>
            ))}
            </div>
        </div>
      );
}

export default Kerdesek;